//TopHober
class Single {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const accTrigger = document.querySelector(".js-movie-acc")
    const accContent = document.querySelector(".js-gallery-movie-content ")
    const body = document.querySelector("body")
    const accOverlay = document.querySelector(".js-gallery-movie-overlay")
    const closeBtn = document.querySelector(".p-design-detail-youtube-close")

    accTrigger.addEventListener("click", () => {
      accContent.classList.add("is-open")
      body.style.overflow = "hidden"
    })

    accOverlay.addEventListener("click", () => {
      accContent.classList.remove("is-open")
      body.style.overflow = "auto"
    })

    closeBtn.addEventListener("click", () => {
      accContent.classList.remove("is-open")
      body.style.overflow = "auto"
    })



  }
}
