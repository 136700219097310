//SmoothScroll
class SupportModal {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(function(){
      // 変数に要素を入れる
      var open = $('.p-support-assurance__btn a'),
        close = $('.modal-close'),
        container = $('.modal-container');
    
      //開くボタンをクリックしたらモーダルを表示する
      open.on('click',function(){ 
        container.addClass('active');
        return false;
      });
    
      //閉じるボタンをクリックしたらモーダルを閉じる
      close.on('click',function(){  
        container.removeClass('active');
      });
    
      //モーダルの外側をクリックしたらモーダルを閉じる
      $(document).on('click',function(e) {
        if(!$(e.target).closest('.modal-body').length) {
          container.removeClass('active');
        }
      });
    });
  }
}