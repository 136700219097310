class MvSlider {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    this.slider = new Swiper(this.el, {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
      effect: 'fade',
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + "0" + (index + 1) + "<svg class='anime' version='1.1' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 45 45'><circle class='st0 svg-elem-1' cx='22.5' cy='22.5' r='21.5' style='fill: none; stroke: rgb(255, 255, 255); stroke-width: 1px;'></circle></svg><svg class='bg' version='1.1' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 45 45'><circle class='st0 svg-elem-1' cx='22.5' cy='22.5' r='21.5' style='fill: none; stroke: rgba(255, 255, 255, .2); stroke-width: 1px;'></circle></svg></span>";
        },
      },
    });
    this.slider.on('slideChange', function () {// スライド切り替え時発火
      if (this.realIndex > 0) {// 1枚目以降の時
        this.params.autoplay.delay = 2000;// 2枚目以降の表示時間を指定
      }
    });
  }
}
