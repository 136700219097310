class DesignSlider {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(".p-design__trigger li").on({
      click: function(e) {
        $(this).siblings().removeClass('is-active');
        $(this).addClass('is-active');
        $('.p-design__listWrap').removeClass('is-active');
      }
    })
    $(".p-design__trigger li").on({
      click: function(e) {
        var index = $(".p-design__trigger li").index(this);
        $('.p-design__trigger li, .p-design__listWrap').removeClass('is-active');
        $(this).addClass('is-active');
        $('.p-design__listWrap').css('display','none').eq(index).fadeIn("slow");
      }
    })

    // if (window.matchMedia('(max-width: 960px)').matches) {
    //   this.slider = new Swiper(this.el, {
    //     autoplay: {
    //       delay: 10000,
    //       disableOnInteraction: false,
    //     },
    //     speed: 1000,
    //     slidesPerView: 'auto',
    //     centeredSlides: true,
    //     spaceBetween: 0,
    //     loop: true,
    //     slidesPerView: 1.2,
    //     // slidesPerView: 1.5,
    //     // spaceBetween: 56,
    //     spaceBetween: 16,
    //   });
    // }

    $(".p-design__listWrap").css({'display': 'none'})
  }
}
