//SmoothScroll
class SupportScroll {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    this.options = {
      root: null,
      rootMargin: "-50% 0%",
      threshold: 0,
    };

    const observerEnter = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const item = entry.target;
        $(".js-sticky a").removeClass("is-active");
        $("[href='#" + entry.target.getAttribute("id") + "'").addClass(
          "is-active"
        );
      });
    }, this.options);

    this.inviewItem = document.querySelectorAll(".observer_ele");
    this.inviewItem.forEach((e) => {
      observerEnter.observe(e);
    });

    $(".js-sticky a").on({
      click: function() {
        let target = $(this);
        $(".js-accordion").removeClass("is-active")

        setTimeout(function() {
          $(".js-sticky a").removeClass('is-active');
          target.addClass('is-active');
        }, 500)
      }
    });

    $(".js-accordion").on({
      click: function() {
        $(this).next().slideToggle();
        $(this).toggleClass('is-active');
      }
    })

    // 幅が768px以上のビューポートをターゲットとするメディアクエリを作成
    const mediaQuery = window.matchMedia('(max-width: 959px)');

    if(mediaQuery.matches) {
      let fixed_el = $(".js-fixed");
      let fixed_top = fixed_el[0].offsetTop;
      let fixed_bottom = $(".p-support-inner__right")[0].offsetTop + $(".p-support-inner__right")[0].offsetHeight;

      $(window).on("scroll", function() {

        let intersection_top = $(window)[0].pageYOffset + $(".l-header")[0].clientHeight;

        if(fixed_top <= intersection_top) {
          fixed_el.addClass('is-active');
        } else {
          fixed_el.removeClass('is-active');
        }

        if(fixed_bottom <= intersection_top) {
          fixed_el.addClass('is-remove');
        } else {
          fixed_el.removeClass('is-remove');
        }
      })

      $(".js-sticky a").click(function() {
        $(this).parent().parent().slideToggle();
      })
    }
    setTimeout(function() {
      $(".p-support-inner__wrap li a").removeClass('is-active');
      $(".p-support-inner__wrap li:first-of-type a").addClass('is-active');
    }, 300)
  }
}