class Modal {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    // コース画像モーダル表示イベント
    $(".modalwindow ul li a").click(function () {
      // まず、クリックした画像の HTML(<img>タグ全体)を#frayDisplay内にコピー
      $("#grayDisplay").html($(this).prop("outerHTML"));
      //そして、fadeInで表示する。
      $("#grayDisplay").fadeIn(200);
      return false;
    });

    // コース画像モーダル非表示イベント
    // モーダル画像背景 または 拡大画像そのものをクリックで発火
    $("#grayDisplay").click(function () {
      // 非表示にする
      $("#grayDisplay").fadeOut(200);
      return false;
    });

  }
}
