class Barba {
  constructor(el, opt){
    this.event();
  }
  event() {	
    let mask = document.querySelector(".mask");
    barba.use(barbaCss);//追記

    barba.init({
      views: [{
        namespace: 'home',
        beforeLeave(data) {
        }
      }, {
        namespace: 'parts',
        beforeEnter(data) {
        }
      }]
    });
  }
}