class Drawer {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    let $el = $(".l-header, .drawer, html");

    $(this.el).on({
      click: function() {
        $el.toggleClass('is-active');
      }
    })
  }
}
