class Parallax {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let para_ele = document.querySelectorAll(this.el);

    para_ele.forEach((ele, index)=> {
      ele.classList.add("js-parallax" + index);
      gsap.to(".js-parallax" + index + ' img', {
        translateY: '-30px',
        scrollTrigger: {
          trigger: '.js-parallax' + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })
  }
}
