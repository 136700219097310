//MovieModal
class MovieModal {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    // $(this.el).on({
    //   click: function() {
    //     $(".js-modal-inner").toggleClass('is-active');
    //     $("." + $(this).attr('data-id')).addClass('is-active').siblings().removeClass('is-active');
    //   }
    // })
    // $(".p-movie_modal__close").on({
    //   click: function() {
    //     $(".js-modal-inner").toggleClass('is-active');
    //     // $(".modal01")[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    //   }
    // })
    // $('.p-movie__ele').on({
    //   mouseover: function() {
    //     $(`.p-movie__bgImg`).eq($(this).index()).addClass('is-active');
    //     $(this).siblings().addClass('is-siblings');
    //     $(this).addClass('is-active');
    //     $('.mouse_stalker').addClass('is-active');
    //   },
    //   mouseout: function() {
    //     $(`.p-movie__bgImg`).eq($(this).index()).removeClass('is-active');
    //     $(this).siblings().removeClass('is-siblings');
    //     $(this).removeClass('is-active');
    //     $('.mouse_stalker').removeClass('is-active');
    //   }
    // })
  }
}
