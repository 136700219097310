//loading
class Loading {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    // if(!$.cookie('loading')) {
    //   const tl = gsap.timeline({
    //     repeat: 0,
    //     onStart: function() {
    //       $("html").css({"overflow": "hidden"})
    //       $(".loading").addClass("on-loading");
    //     },
    //   });
    //   tl.add(gsap.to(".l-txt-wrap path", .5, {
    //     translateY: 0,
    //     ease: "cubic-bezier(0,.22,0,.5)",
    //     opacity: 1,
    //     stagger: .07,
    //   }));
    //   tl.add(gsap.to(".l-txt-wrap .delete", .2, {
    //     ease: "cubic-bezier(0,.22,0,.5)",
    //     opacity: 0,
    //     // stagger: .1,
    //     delay: 1,
    //   }));
    //   if (window.matchMedia('(max-width: 960px)').matches) {
    //     tl.add(gsap.to(".l-txt-wrap .initial_d", 1, {
    //       translateX: '222',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_i", 1, {
    //       translateX: '49',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_p", 1, {
    //       translateX: '-217',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".loading__txt01", 2, {
    //       scale: 1.5,
    //     }),"<=");
    //   } else {
    //     tl.add(gsap.to(".l-txt-wrap .initial_d", 1, {
    //       translateX: '24.5rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_i", 1, {
    //       translateX: '7.5rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_p", 1, {
    //       translateX: '-19rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".loading__txt01", 2, {
    //       scale: 1,
    //     }),"<=");
    //   }
    //   tl.add(gsap.to(".loading__txt02", 1, {
    //     opacity: 1,
    //     onComplete: function() {
    //       setTimeout(function() {
    //         $("#tegaki-mask-line").addClass('is-active');
    //       }, 500)
    //     },
    //   }),"-=1");
    //   tl.add(gsap.to(".loading_bg_white", 1, {
    //     opacity: 0,
    //     delay: 3,
    //     onStart: function() {
    //       $(".loading").css({"pointerEvents": "none", "position": "absolute"});
    //       $(".loading__txt01 path, .loading__txt02 path, .loading__txt03 path").addClass('is-active');
    //       let mvSlider = new MvSlider('.mvSwiper');
    //     },
    //     onComplete: function() {
    //       $("html").css({"overflow": "inherit"})
    //       $(".loading").css({"zIndex": "97"})
    //     }
    //   }));

    //   $.cookie('loading', 'true', { expires: 1 , path: '/' , expires: .04 });

    // } else {

      const tl = gsap.timeline({
        repeat: 0,
      });

      let mvSlider = new MvSlider('.mvSwiper');
      $(".loading").addClass("no-loading").css({"zIndex": "97"});
    // }
  }
}
