//noScroll
class TtlAnime {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    if($('.split_txt').length) {
      $('.split_txt').children().addBack().contents().each(function(e) {
        if (this.nodeType == 3) {
          $(this).replaceWith($(this).text().replace(/(\S)/g, `<em>$1</em>`));
        }
      });
      $('.split_txt').each(function() {
        $(this).find('em').each(function(e) {
          $(this).css({"transition-delay": `${e * 0.05}s`, "animation-delay": `${e * 0.05}s`});
        })
      })
    }
  }
}
