// globals
class Accordion {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function () {
      // タイトルをクリックすると
      $(".js-accordion-title").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

    $(function () {
      //家づくり相談シングルページ
      $(".p-counseling-detail__acco").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });

      $(".p-counseling-detail__acco-item").on("click", function() {
        $(".p-counseling-detail__acco-list").slideUp(300);
        $(".p-counseling-detail").removeClass("white-out");
        // $(function(){
        //   setTimeout(function(){
        //     $(".p-counseling-detail").addClass("white-out");
        //   },500);
        // });
      })
    });

    $(function () {
      //ヘッダー
      $(".drawer-works-acco").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

    $(function () {
      //フッター
      $(".f-menu__acco-event").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });

  }
}

class Barba {
  constructor(el, opt){
    this.event();
  }
  event() {	
    let mask = document.querySelector(".mask");
    barba.use(barbaCss);//追記

    barba.init({
      views: [{
        namespace: 'home',
        beforeLeave(data) {
        }
      }, {
        namespace: 'parts',
        beforeEnter(data) {
        }
      }]
    });
  }
}
var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
class DesignSlider {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(".p-design__trigger li").on({
      click: function(e) {
        $(this).siblings().removeClass('is-active');
        $(this).addClass('is-active');
        $('.p-design__listWrap').removeClass('is-active');
      }
    })
    $(".p-design__trigger li").on({
      click: function(e) {
        var index = $(".p-design__trigger li").index(this);
        $('.p-design__trigger li, .p-design__listWrap').removeClass('is-active');
        $(this).addClass('is-active');
        $('.p-design__listWrap').css('display','none').eq(index).fadeIn("slow");
      }
    })

    // if (window.matchMedia('(max-width: 960px)').matches) {
    //   this.slider = new Swiper(this.el, {
    //     autoplay: {
    //       delay: 10000,
    //       disableOnInteraction: false,
    //     },
    //     speed: 1000,
    //     slidesPerView: 'auto',
    //     centeredSlides: true,
    //     spaceBetween: 0,
    //     loop: true,
    //     slidesPerView: 1.2,
    //     // slidesPerView: 1.5,
    //     // spaceBetween: 56,
    //     spaceBetween: 16,
    //   });
    // }

    $(".p-design__listWrap").css({'display': 'none'})
  }
}

class DesignSlider02 {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    let swiper;
    $(".modal-open").on('click', function(){
      swiper = new Swiper(".swiper-modal", {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        initialSlide: $(this).data('num'),
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
      $(".p-design-detail-gallery-modal").addClass('is-active');
      return false;
    })

    $('.p-design-detail-gallery-modal__close, .p-design-detail-gallery-modal__overflow').on('click', function() {
      $('.p-design-detail-gallery-modal').removeClass('is-active');
      setTimeout(function() {
        swiper.destroy(false,true);
      }, 300)
    })
  }
}

class Drawer {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    let $el = $(".l-header, .drawer, html");

    $(this.el).on({
      click: function() {
        $el.toggleClass('is-active');
      }
    })
  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.3 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}

//loading
class Loading {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    // if(!$.cookie('loading')) {
    //   const tl = gsap.timeline({
    //     repeat: 0,
    //     onStart: function() {
    //       $("html").css({"overflow": "hidden"})
    //       $(".loading").addClass("on-loading");
    //     },
    //   });
    //   tl.add(gsap.to(".l-txt-wrap path", .5, {
    //     translateY: 0,
    //     ease: "cubic-bezier(0,.22,0,.5)",
    //     opacity: 1,
    //     stagger: .07,
    //   }));
    //   tl.add(gsap.to(".l-txt-wrap .delete", .2, {
    //     ease: "cubic-bezier(0,.22,0,.5)",
    //     opacity: 0,
    //     // stagger: .1,
    //     delay: 1,
    //   }));
    //   if (window.matchMedia('(max-width: 960px)').matches) {
    //     tl.add(gsap.to(".l-txt-wrap .initial_d", 1, {
    //       translateX: '222',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_i", 1, {
    //       translateX: '49',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_p", 1, {
    //       translateX: '-217',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".loading__txt01", 2, {
    //       scale: 1.5,
    //     }),"<=");
    //   } else {
    //     tl.add(gsap.to(".l-txt-wrap .initial_d", 1, {
    //       translateX: '24.5rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_i", 1, {
    //       translateX: '7.5rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".l-txt-wrap .initial_p", 1, {
    //       translateX: '-19rem',
    //       ease: "cubic-bezier(0,.22,0,.5)",
    //     }),"<=");
    //     tl.add(gsap.to(".loading__txt01", 2, {
    //       scale: 1,
    //     }),"<=");
    //   }
    //   tl.add(gsap.to(".loading__txt02", 1, {
    //     opacity: 1,
    //     onComplete: function() {
    //       setTimeout(function() {
    //         $("#tegaki-mask-line").addClass('is-active');
    //       }, 500)
    //     },
    //   }),"-=1");
    //   tl.add(gsap.to(".loading_bg_white", 1, {
    //     opacity: 0,
    //     delay: 3,
    //     onStart: function() {
    //       $(".loading").css({"pointerEvents": "none", "position": "absolute"});
    //       $(".loading__txt01 path, .loading__txt02 path, .loading__txt03 path").addClass('is-active');
    //       let mvSlider = new MvSlider('.mvSwiper');
    //     },
    //     onComplete: function() {
    //       $("html").css({"overflow": "inherit"})
    //       $(".loading").css({"zIndex": "97"})
    //     }
    //   }));

    //   $.cookie('loading', 'true', { expires: 1 , path: '/' , expires: .04 });

    // } else {

      const tl = gsap.timeline({
        repeat: 0,
      });

      let mvSlider = new MvSlider('.mvSwiper');
      $(".loading").addClass("no-loading").css({"zIndex": "97"});
    // }
  }
}

class Modal {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    // コース画像モーダル表示イベント
    $(".modalwindow ul li a").click(function () {
      // まず、クリックした画像の HTML(<img>タグ全体)を#frayDisplay内にコピー
      $("#grayDisplay").html($(this).prop("outerHTML"));
      //そして、fadeInで表示する。
      $("#grayDisplay").fadeIn(200);
      return false;
    });

    // コース画像モーダル非表示イベント
    // モーダル画像背景 または 拡大画像そのものをクリックで発火
    $("#grayDisplay").click(function () {
      // 非表示にする
      $("#grayDisplay").fadeOut(200);
      return false;
    });

  }
}

class MovieEvent {
  constructor(el, opt){
    this.el = el;
    this.flag = true;
    if($(this.el).length > 0){
      this.setting();
    }
  }
  setting() {
    let optionsAll = {
      root: null,
      threshold: [0, 0.3, 0.5, 1]
    }
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(!entry.isIntersecting){
          this.movieStop(item);
        }
        if(entry.intersectionRatio >= 0.3) {
          this.movieStart(item);
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    inviewItem.forEach((e)=>{
      observerAll.observe(e);
    });
    if($(".js-sound").length) {
      this.volumeSwitch(this.el);
    }
  }
  movieStart(item){
    $(item).get(0).play();
    this.movieEnded(item);
  }
  movieStop(item){
    $(item).get(0).pause();
    $(item).get(0).currentTime = 0;
  }
  movieEnded(item){
    $(item).on("ended", (e)=> {
      var $this = $(e.currentTarget);
      $this.get(0).currentTime = 0;
      $this.get(0).play();
      // this.nextScene();
    });
  }
  volumeSwitch(ele){
    $(".js-sound").on({
      click: function() {
        if(this.flag == true) {
          $(ele).prop('muted', true);
          this.flag = false;
        } else {
          $(ele).prop('muted', false);
          this.flag = true;
        }
        $(".js-sound").toggleClass('is-active');
      }
    });
  }
}

//MovieModal
class MovieModal {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    // $(this.el).on({
    //   click: function() {
    //     $(".js-modal-inner").toggleClass('is-active');
    //     $("." + $(this).attr('data-id')).addClass('is-active').siblings().removeClass('is-active');
    //   }
    // })
    // $(".p-movie_modal__close").on({
    //   click: function() {
    //     $(".js-modal-inner").toggleClass('is-active');
    //     // $(".modal01")[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    //   }
    // })
    // $('.p-movie__ele').on({
    //   mouseover: function() {
    //     $(`.p-movie__bgImg`).eq($(this).index()).addClass('is-active');
    //     $(this).siblings().addClass('is-siblings');
    //     $(this).addClass('is-active');
    //     $('.mouse_stalker').addClass('is-active');
    //   },
    //   mouseout: function() {
    //     $(`.p-movie__bgImg`).eq($(this).index()).removeClass('is-active');
    //     $(this).siblings().removeClass('is-siblings');
    //     $(this).removeClass('is-active');
    //     $('.mouse_stalker').removeClass('is-active');
    //   }
    // })
  }
}

class MvSlider {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    this.slider = new Swiper(this.el, {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
      effect: 'fade',
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + "0" + (index + 1) + "<svg class='anime' version='1.1' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 45 45'><circle class='st0 svg-elem-1' cx='22.5' cy='22.5' r='21.5' style='fill: none; stroke: rgb(255, 255, 255); stroke-width: 1px;'></circle></svg><svg class='bg' version='1.1' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 45 45'><circle class='st0 svg-elem-1' cx='22.5' cy='22.5' r='21.5' style='fill: none; stroke: rgba(255, 255, 255, .2); stroke-width: 1px;'></circle></svg></span>";
        },
      },
    });
    this.slider.on('slideChange', function () {// スライド切り替え時発火
      if (this.realIndex > 0) {// 1枚目以降の時
        this.params.autoplay.delay = 2000;// 2枚目以降の表示時間を指定
      }
    });
  }
}

class Parallax {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let para_ele = document.querySelectorAll(this.el);

    para_ele.forEach((ele, index)=> {
      ele.classList.add("js-parallax" + index);
      gsap.to(".js-parallax" + index + ' img', {
        translateY: '-30px',
        scrollTrigger: {
          trigger: '.js-parallax' + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })
  }
}

//SmoothScroll
class SmoothScroll {
  constructor(){
    this.event();
  }
  event(){
    var headerHeight = $(".l-header")[0].offsetHeight;     //fixedのヘッダーの高さを取得
    var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 500;   //スクロールのアニメーションスピード
    if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }, 100);
    }
    $('a[href*="#"]').on({
      'click': function(){
        var href = $(this).attr("href");
        if(href.charAt(0) == "#") {
          var target = $(href);
        } else {
          href = href.split('#')
          var target = $('#' + href[1]);
        }
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }
    });
  }
}

//SmoothScroll
class SupportModal {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(function(){
      // 変数に要素を入れる
      var open = $('.p-support-assurance__btn a'),
        close = $('.modal-close'),
        container = $('.modal-container');
    
      //開くボタンをクリックしたらモーダルを表示する
      open.on('click',function(){ 
        container.addClass('active');
        return false;
      });
    
      //閉じるボタンをクリックしたらモーダルを閉じる
      close.on('click',function(){  
        container.removeClass('active');
      });
    
      //モーダルの外側をクリックしたらモーダルを閉じる
      $(document).on('click',function(e) {
        if(!$(e.target).closest('.modal-body').length) {
          container.removeClass('active');
        }
      });
    });
  }
}
//SmoothScroll
class SupportScroll {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    this.options = {
      root: null,
      rootMargin: "-50% 0%",
      threshold: 0,
    };

    const observerEnter = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const item = entry.target;
        $(".js-sticky a").removeClass("is-active");
        $("[href='#" + entry.target.getAttribute("id") + "'").addClass(
          "is-active"
        );
      });
    }, this.options);

    this.inviewItem = document.querySelectorAll(".observer_ele");
    this.inviewItem.forEach((e) => {
      observerEnter.observe(e);
    });

    $(".js-sticky a").on({
      click: function() {
        let target = $(this);
        $(".js-accordion").removeClass("is-active")

        setTimeout(function() {
          $(".js-sticky a").removeClass('is-active');
          target.addClass('is-active');
        }, 500)
      }
    });

    $(".js-accordion").on({
      click: function() {
        $(this).next().slideToggle();
        $(this).toggleClass('is-active');
      }
    })

    // 幅が768px以上のビューポートをターゲットとするメディアクエリを作成
    const mediaQuery = window.matchMedia('(max-width: 959px)');

    if(mediaQuery.matches) {
      let fixed_el = $(".js-fixed");
      let fixed_top = fixed_el[0].offsetTop;
      let fixed_bottom = $(".p-support-inner__right")[0].offsetTop + $(".p-support-inner__right")[0].offsetHeight;

      $(window).on("scroll", function() {

        let intersection_top = $(window)[0].pageYOffset + $(".l-header")[0].clientHeight;

        if(fixed_top <= intersection_top) {
          fixed_el.addClass('is-active');
        } else {
          fixed_el.removeClass('is-active');
        }

        if(fixed_bottom <= intersection_top) {
          fixed_el.addClass('is-remove');
        } else {
          fixed_el.removeClass('is-remove');
        }
      })

      $(".js-sticky a").click(function() {
        $(this).parent().parent().slideToggle();
      })
    }
    setTimeout(function() {
      $(".p-support-inner__wrap li a").removeClass('is-active');
      $(".p-support-inner__wrap li:first-of-type a").addClass('is-active');
    }, 300)
  }
}
//TopHober
class TopHover {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(this.el).on({
      mouseover: function() {
        $(this).parent().parent().next().addClass('is-active');
      },
      mouseout: function() {
        $(this).parent().parent().next().removeClass('is-active');
      }
    })
  }
}

//noScroll
class TtlAnime {
  constructor(el,opt){
    this.el = el;
    this.event();
  }
  event(){
    if($('.split_txt').length) {
      $('.split_txt').children().addBack().contents().each(function(e) {
        if (this.nodeType == 3) {
          $(this).replaceWith($(this).text().replace(/(\S)/g, `<em>$1</em>`));
        }
      });
      $('.split_txt').each(function() {
        $(this).find('em').each(function(e) {
          $(this).css({"transition-delay": `${e * 0.05}s`, "animation-delay": `${e * 0.05}s`});
        })
      })
    }
  }
}

// globals
//TopHober
class Movies {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const modalTrigger = document.querySelectorAll(".js-modal-trigger")
    const modalContent = document.querySelectorAll(".js-movies-modal")
    const modalOverlay = document.querySelectorAll(".movies-modal__overlay")
    const body = document.querySelector("body")
    const modalCloseBtn = document.querySelectorAll(".movies-modal__close")

    modalTrigger.forEach((trigger,triggerNum) => {

      trigger.addEventListener("click", () => {
      modalContent.forEach((content,contentNum) => {
            if(triggerNum === contentNum){
              content.classList.add("is-active")
              body.style.overflow ="hidden"
            }
          });

      })

    });

    modalOverlay.forEach((ele) => {
      ele.addEventListener("click", () => {
        modalContent.forEach((content) => {
          content.classList.remove("is-active")
          body.style.overflow ="auto"
        });
      })
    })

    modalCloseBtn.forEach(ele => {
      ele.addEventListener("click", () => {
        modalContent.forEach((content) => {
          content.classList.remove("is-active")
          body.style.overflow ="auto"
        });
      })
    })


  }
}

//TopHober
class Single {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const accTrigger = document.querySelector(".js-movie-acc")
    const accContent = document.querySelector(".js-gallery-movie-content ")
    const body = document.querySelector("body")
    const accOverlay = document.querySelector(".js-gallery-movie-overlay")
    const closeBtn = document.querySelector(".p-design-detail-youtube-close")

    accTrigger.addEventListener("click", () => {
      accContent.classList.add("is-open")
      body.style.overflow = "hidden"
    })

    accOverlay.addEventListener("click", () => {
      accContent.classList.remove("is-open")
      body.style.overflow = "auto"
    })

    closeBtn.addEventListener("click", () => {
      accContent.classList.remove("is-open")
      body.style.overflow = "auto"
    })



  }
}

//TopHober
class PageTop {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    var stalkerElem   = document.querySelector('.mouse_stalker'),
    scrollingElem = document.scrollingElement || document.documentElement || document.body;
  
    scrollingElem.addEventListener('mousemove', function(e) {
      stalkerElem.style.top  = e.clientY + 'px';
      stalkerElem.style.left = e.clientX + 'px';
    });
  
    gsap.to('.p-concept-img__left figure img', {
      translateY: '1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__left figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-concept-img__center figure img', {
      translateY: '-1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__center figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-concept-img__right figure img', {
      translateY: '1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__right figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-quality-img img', {
      translateY: '-3rem',
      scrollTrigger: {
        trigger: '.p-quality-img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })

    const heading = document.querySelector('.p-mv');

    // オプション指定
    const options = {
      threshold: 0 // 指定範囲は0 〜 1
    };

    // 実行するよ
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.intersectionRatio == 0) {
          $('.js-fixedBanner').addClass('is-active');
        } else {
          $('.js-fixedBanner').removeClass('is-active');
        }
      });
    }, options);

    observer.observe(heading);
  }
}


(() => {

})();

//DOMContentLoaded
$(() => {

  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  let vw = window.innerWidth;

  window.addEventListener('resize', () => {
    if (vw === window.innerWidth) {
      // 画面の横幅にサイズ変動がないので処理を終える
      return;
    }
    // 画面の横幅のサイズ変動があった時のみ高さを再計算する
    vw = window.innerWidth;
    setFillHeight();
  });

  // 初期化
  setFillHeight();

  let brawer = new Drawer('.js-header-btn');
  let parallax = new Parallax('.js-parallax');
  let accordion = new Accordion(".accordion");
  // let barba = new Barba();
  let smoothScroll = new SmoothScroll();
  let inviewEvent = new InviewEvent('.js-inview');
  let designSlider = new DesignSlider('.p-design__trigger');
  let designSlider02 = new DesignSlider02('.swiper-modal');
  let movieModal = new MovieModal('.js-modal');
  let topHover = new TopHover('.js-topHover');
  let supportScroll = new SupportScroll('.p-support-inner');
  let loading = new Loading('.loading');
  let ttlAnime = new TtlAnime();
  let pageTop = new PageTop('.p-top');
  let movies = new Movies('.p-movies');
  let movieEvent = new MovieEvent('.js-movie');
  let supportModal = new SupportModal('.p-support-assurance__btn a');
  let single = new Single('.p-single');
  // let scrollHint = new ScrollHint('.js-scrollable');
  let scrollHint = new ScrollHint('.js-scrollable', {
    scrollHintIconAppendClass: 'scroll-hint-icon-white',
    i18n: {
      scrollable: 'スクロールできます'
    }
  });

  setTimeout(() => {
    let options = {
      threshold: 0
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          $('.scroll-hint-icon-wrap').addClass('is-active');
        }
      });
    }, options);
    let el = document.querySelector('.s-flow__table')
    observerEnter.observe(el);
  }, 500);

  $(window).scroll(function() {
    $('.scroll-hint-icon-wrap').addClass('is-active');
  })



  $('.gallery').modaal({
    type: 'image'
  });

  if($('.p-top').length) {
    gsap.to('.l-header', {
      scrollTrigger: {
        trigger: '.p-mv', // 要素がビューポートに入ったときにアニメーション開始
        onLeave: function() {
          $(".l-header").addClass('is-active');
        },
        onEnterBack: function() {
          $(".l-header").removeClass('is-active');
        }
      },
    })
  } else {
    $('.l-header').addClass('is-active');
  }

  gsap.to('.l-footer__bg img', {
    translateY: '-2rem',
    scrollTrigger: {
      trigger: '.l-footer', // 要素がビューポートに入ったときにアニメーション開始
      scrub: 3,
    }
  })

  $("#zipcode").zip2addr("#address");
});

//images resources loaded
$(window).on('load', () => {
});

//after loading animation
$(window).on('loading', () => {
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });


// // IFrame Player API の読み込みタグを挿入
// const tag = document.createElement('script')
// tag.src = "https://www.youtube.com/iframe_api"
// const firstScriptTag = document.getElementsByTagName('script')[0]
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

// // 関数onYouTubeIframeAPIReadyでiframeとYoutubeプレイヤーを作成

// let player01, player02, player03, player04, player05, player06
// function onYouTubeIframeAPIReady() {
//   player01 = new YT.Player('hvr_bg01', {
//     videoId: 'noaMBJBSVdM',
//     playerVars: {
//       'autoplay': 1,
//       'mute': 1,
//       'controls': 0,
//       'playlist': 'noaMBJBSVdM',
//       'rel': 0,
//       'loop': 1
//     },
//   })
//   player02 = new YT.Player('hvr_bg02', {
//     videoId: 'RXXN5Vof_Po',
//     playerVars: {
//       'autoplay': 1,
//       'mute': 1,
//       'controls': 0,
//       'playlist': 'RXXN5Vof_Po',
//       'rel': 0,
//       'loop': 1
//     },
//   })
//   player03 = new YT.Player('hvr_bg03', {
//     videoId: '1XYs6tUc-FM',
//     playerVars: {
//       'autoplay': 1,
//       'mute': 1,
//       'controls': 0,
//       'playlist': '1XYs6tUc-FM',
//       'rel': 0,
//       'loop': 1
//     },
//   })
//   player04 = new YT.Player('modal01', {
//     videoId: 'noaMBJBSVdM',
//     playerVars: {
//       // 'autoplay': 1,
//       // 'mute': 1,
//       // 'controls': 0,
//       'playlist': 'noaMBJBSVdM',
//       // 'rel': 0,
//       // 'loop': 1
//     },
//   })
//   player05 = new YT.Player('modal02', {
//     videoId: 'RXXN5Vof_Po',
//     playerVars: {
//       // 'autoplay': 1,
//       // 'mute': 1,
//       // 'controls': 0,
//       'playlist': 'RXXN5Vof_Po',
//       // 'rel': 0,
//       // 'loop': 1
//     },
//   })
//   player06 = new YT.Player('modal03', {
//     videoId: '1XYs6tUc-FM',
//     playerVars: {
//       // 'autoplay': 1,
//       // 'mute': 1,
//       // 'controls': 0,
//       'playlist': '1XYs6tUc-FM',
//       // 'rel': 0,
//       // 'loop': 1
//     },
//   })
// }

// // 動画の操作
// if(document.querySelector('.p-movie_modal__close')) {
//   const pauseButton = document.querySelector('.p-movie_modal__close');

//   pauseButton.addEventListener('click', ()=> {
//     player04.pauseVideo()
//     player05.pauseVideo()
//     player06.pauseVideo()
//   })
// }