//TopHober
class TopHover {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(this.el).on({
      mouseover: function() {
        $(this).parent().parent().next().addClass('is-active');
      },
      mouseout: function() {
        $(this).parent().parent().next().removeClass('is-active');
      }
    })
  }
}
