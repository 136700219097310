class DesignSlider02 {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    let swiper;
    $(".modal-open").on('click', function(){
      swiper = new Swiper(".swiper-modal", {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        initialSlide: $(this).data('num'),
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
      $(".p-design-detail-gallery-modal").addClass('is-active');
      return false;
    })

    $('.p-design-detail-gallery-modal__close, .p-design-detail-gallery-modal__overflow').on('click', function() {
      $('.p-design-detail-gallery-modal').removeClass('is-active');
      setTimeout(function() {
        swiper.destroy(false,true);
      }, 300)
    })
  }
}
