class MovieEvent {
  constructor(el, opt){
    this.el = el;
    this.flag = true;
    if($(this.el).length > 0){
      this.setting();
    }
  }
  setting() {
    let optionsAll = {
      root: null,
      threshold: [0, 0.3, 0.5, 1]
    }
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(!entry.isIntersecting){
          this.movieStop(item);
        }
        if(entry.intersectionRatio >= 0.3) {
          this.movieStart(item);
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    inviewItem.forEach((e)=>{
      observerAll.observe(e);
    });
    if($(".js-sound").length) {
      this.volumeSwitch(this.el);
    }
  }
  movieStart(item){
    $(item).get(0).play();
    this.movieEnded(item);
  }
  movieStop(item){
    $(item).get(0).pause();
    $(item).get(0).currentTime = 0;
  }
  movieEnded(item){
    $(item).on("ended", (e)=> {
      var $this = $(e.currentTarget);
      $this.get(0).currentTime = 0;
      $this.get(0).play();
      // this.nextScene();
    });
  }
  volumeSwitch(ele){
    $(".js-sound").on({
      click: function() {
        if(this.flag == true) {
          $(ele).prop('muted', true);
          this.flag = false;
        } else {
          $(ele).prop('muted', false);
          this.flag = true;
        }
        $(".js-sound").toggleClass('is-active');
      }
    });
  }
}
