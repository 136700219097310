//TopHober
class Movies {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const modalTrigger = document.querySelectorAll(".js-modal-trigger")
    const modalContent = document.querySelectorAll(".js-movies-modal")
    const modalOverlay = document.querySelectorAll(".movies-modal__overlay")
    const body = document.querySelector("body")
    const modalCloseBtn = document.querySelectorAll(".movies-modal__close")

    modalTrigger.forEach((trigger,triggerNum) => {

      trigger.addEventListener("click", () => {
      modalContent.forEach((content,contentNum) => {
            if(triggerNum === contentNum){
              content.classList.add("is-active")
              body.style.overflow ="hidden"
            }
          });

      })

    });

    modalOverlay.forEach((ele) => {
      ele.addEventListener("click", () => {
        modalContent.forEach((content) => {
          content.classList.remove("is-active")
          body.style.overflow ="auto"
        });
      })
    })

    modalCloseBtn.forEach(ele => {
      ele.addEventListener("click", () => {
        modalContent.forEach((content) => {
          content.classList.remove("is-active")
          body.style.overflow ="auto"
        });
      })
    })


  }
}
