//TopHober
class PageTop {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    var stalkerElem   = document.querySelector('.mouse_stalker'),
    scrollingElem = document.scrollingElement || document.documentElement || document.body;
  
    scrollingElem.addEventListener('mousemove', function(e) {
      stalkerElem.style.top  = e.clientY + 'px';
      stalkerElem.style.left = e.clientX + 'px';
    });
  
    gsap.to('.p-concept-img__left figure img', {
      translateY: '1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__left figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-concept-img__center figure img', {
      translateY: '-1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__center figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-concept-img__right figure img', {
      translateY: '1rem',
      scrollTrigger: {
        trigger: '.p-concept-img__right figure img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })
  
    gsap.to('.p-quality-img img', {
      translateY: '-3rem',
      scrollTrigger: {
        trigger: '.p-quality-img', // 要素がビューポートに入ったときにアニメーション開始
        scrub: 3,
      }
    })

    const heading = document.querySelector('.p-mv');

    // オプション指定
    const options = {
      threshold: 0 // 指定範囲は0 〜 1
    };

    // 実行するよ
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.intersectionRatio == 0) {
          $('.js-fixedBanner').addClass('is-active');
        } else {
          $('.js-fixedBanner').removeClass('is-active');
        }
      });
    }, options);

    observer.observe(heading);
  }
}
